import React, { Suspense } from 'react'
import { Route, BrowserRouter, Routes } from 'react-router-dom'
import HookieFormPage from '../pages/HookieFormPage/HookieFormPage'

const ExportPage = React.lazy(async () => await import('../pages/ExportPage/ExportPage'))
const MembershipFormPage = React.lazy(async () => await import('../pages/MembershipFormPage/MembershipFormPage'))
const NotFoundPage = React.lazy(async () => await import('../pages/NotFoundPage/NotFoundPage'))
const HomePage = React.lazy(async () => await import('../pages/HomePage/HomePage'))

const AppRouter = (): JSX.Element => (
  <BrowserRouter>
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<HomePage/>}/>
        <Route path="/membership-form" element={<MembershipFormPage/>}/>
        <Route path="/hookie" element={<HookieFormPage/>}/>
        <Route path="/xO4KIfjIaeak3MBcmk9L1OPYme1bVc/exports" element={<ExportPage/>}/>
        <Route path="*" element={<NotFoundPage/>}/>
      </Routes>
    </Suspense>
  </BrowserRouter>
)

export default AppRouter
