import { query, doc, collection, setDoc, getDocs, serverTimestamp } from '@firebase/firestore'
import { type FormData } from '../models/FormData'
import { FirebaseDB } from '../config/firebase'
import { sendEmailAnniversarySignUp, sendEmailHookie, sendEmailRegistration } from './mailerService'
import { type AnniversaryFormData } from '../models/AnniversaryFormData'
import { type HookieFormData } from '../models/HookieFormData'

export interface FirebaseResponse<T> {
  success: boolean
  data?: T
  error?: string
}

const collections = {
  renovation: 'renovation',
  renovation2425: 'renovation2425',
  anniversary: 'anniversary',
  hookie: 'hookie'
}

export const sendRenovation = async (data: FormData): Promise<FirebaseResponse<string>> => {
  try {
    data.createdAt = serverTimestamp()

    const newDoc = doc(collection(FirebaseDB, collections.renovation))

    await setDoc(newDoc, data)

    await sendEmailRegistration(data)

    return {
      success: true,
      data: newDoc.id
    }
  } catch (error: any) {
    return {
      success: false,
      error: error.message
    }
  }
}

export const sendRenovation2425 = async (data: FormData): Promise<FirebaseResponse<string>> => {
  try {
    data.createdAt = serverTimestamp()

    const newDoc = doc(collection(FirebaseDB, collections.renovation2425))

    await setDoc(newDoc, data)

    await sendEmailRegistration(data)

    return {
      success: true,
      data: newDoc.id
    }
  } catch (error: any) {
    return {
      success: false,
      error: error.message
    }
  }
}

export const sendHookie = async (data: HookieFormData): Promise<FirebaseResponse<string>> => {
  try {
    data.createdAt = serverTimestamp()

    const newDoc = doc(collection(FirebaseDB, collections.hookie))

    await setDoc(newDoc, data)

    await sendEmailHookie(data)

    return {
      success: true,
      data: newDoc.id
    }
  } catch (error: any) {
    return {
      success: false,
      error: error.message
    }
  }
}

export const sendAnniversarySignUp = async (data: AnniversaryFormData): Promise<FirebaseResponse<string>> => {
  try {
    data.createdAt = serverTimestamp()

    const newDoc = doc(collection(FirebaseDB, collections.anniversary))

    await setDoc(newDoc, data)

    await sendEmailAnniversarySignUp(data)

    return {
      success: true,
      data: newDoc.id
    }
  } catch (error: any) {
    return {
      success: false,
      error: error.message
    }
  }
}

export const getAllRenovations = async (): Promise<FirebaseResponse<FormData[]>> => {
  try {
    const q = query(collection(FirebaseDB, collections.renovation))
    const docSnap = await getDocs(q)

    if (!docSnap.empty) {
      return {
        success: true,
        data: docSnap.docs.map(doc => doc.data()) as FormData[]
      }
    } else {
      return {
        success: false,
        error: 'No such document!'
      }
    }
  } catch (error: any) {
    return {
      success: false,
      error: error.message
    }
  }
}

export const getAllRenovations2425 = async (): Promise<FirebaseResponse<FormData[]>> => {
  try {
    const q = query(collection(FirebaseDB, collections.renovation2425))
    const docSnap = await getDocs(q)

    if (!docSnap.empty) {
      return {
        success: true,
        data: docSnap.docs.map(doc => doc.data()) as FormData[]
      }
    } else {
      return {
        success: false,
        error: 'No such document!'
      }
    }
  } catch (error: any) {
    return {
      success: false,
      error: error.message
    }
  }
}

export const getAllAnniversaryInscriptions = async (): Promise<FirebaseResponse<AnniversaryFormData[]>> => {
  try {
    const q = query(collection(FirebaseDB, collections.anniversary))
    const docSnap = await getDocs(q)

    if (!docSnap.empty) {
      return {
        success: true,
        data: docSnap.docs.map(doc => doc.data()) as AnniversaryFormData[]
      }
    } else {
      return {
        success: false,
        error: 'No such document!'
      }
    }
  } catch (error: any) {
    return {
      success: false,
      error: error.message
    }
  }
}

export const getAllHookiesRegistrations = async (): Promise<FirebaseResponse<HookieFormData[]>> => {
  try {
    const q = query(collection(FirebaseDB, collections.hookie))
    const docSnap = await getDocs(q)

    if (!docSnap.empty) {
      return {
        success: true,
        data: docSnap.docs.map(doc => doc.data()) as HookieFormData[]
      }
    } else {
      return {
        success: false,
        error: 'No such document!'
      }
    }
  } catch (error: any) {
    return {
      success: false,
      error: error.message
    }
  }
}

export const getNumberOfSignUps = async (): Promise<number> => {
  try {
    const q = query(collection(FirebaseDB, collections.anniversary))
    const docSnap = await getDocs(q)

    // Filtramos por los que tengan más de 16 años
    const filtered = docSnap.docs.filter(doc => {
      const birthDate = doc.data().birthDate
      const date = new Date(birthDate)
      const age = new Date().getFullYear() - date.getFullYear()
      return age > 16 || birthDate === undefined
    })

    return filtered.length
  } catch (error: any) {
    return 0
  }
}
